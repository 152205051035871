<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#C7CFE2;"
      d="M493.037,426.667H18.963C8.489,426.667,0,418.177,0,407.704V66.37
	c0-10.473,8.489-18.963,18.963-18.963h474.074c10.473,0,18.963,8.489,18.963,18.963v341.333
	C512,418.177,503.511,426.667,493.037,426.667z"
    />
    <path
      style="fill:#AFB9D2;"
      d="M0,369.778v37.926c0,10.472,8.491,18.963,18.963,18.963h188.576l-8.428,75.852h113.778l-8.428-75.852
	h188.576c10.472,0,18.963-8.491,18.963-18.963v-37.926H0z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M464.593,379.259H47.407c-5.236,0-9.481-4.245-9.481-9.482V94.815c0-5.236,4.245-9.481,9.481-9.481
	h417.185c5.236,0,9.481,4.245,9.481,9.481v274.963C474.074,375.014,469.829,379.259,464.593,379.259z"
    />
    <path
      style="fill:#C7CFE2;"
      d="M350.815,512h-189.63c-5.241,0-9.481-4.241-9.481-9.481s4.241-9.481,9.481-9.481h189.63
	c5.241,0,9.481,4.241,9.481,9.481S356.056,512,350.815,512z"
    />
    <polygon
      style="fill:#959CB5;"
      points="304.461,426.667 207.539,426.667 205.432,445.63 306.568,445.63 "
    />
    <path
      style="fill:#707487;"
      d="M398.222,331.852H113.778c-10.473,0-18.963-8.49-18.963-18.963v-37.926
	c0-10.473,8.489-18.963,18.963-18.963h284.444c10.473,0,18.963,8.489,18.963,18.963v37.926
	C417.185,323.362,408.696,331.852,398.222,331.852z"
    />
    <path
      style="fill:#464655;"
      d="M388.741,303.407H123.259c-5.236,0-9.481-4.245-9.481-9.481l0,0c0-5.236,4.245-9.481,9.481-9.481
	h265.482c5.236,0,9.481,4.245,9.481,9.481l0,0C398.222,299.162,393.977,303.407,388.741,303.407z"
    />
    <path
      style="fill:#FFDC64;"
      d="M369.778,0H142.222c-5.236,0-9.481,4.245-9.481,9.481v293.926h246.519V9.481
	C379.259,4.245,375.014,0,369.778,0z"
    />
    <rect
      x="132.741"
      y="284.444"
      style="fill:#FFC850;"
      width="246.519"
      height="18.963"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M218.074,104.296c-5.241,0-9.481-4.241-9.481-9.481V47.407c0-5.241,4.241-9.481,9.481-9.481
		c5.241,0,9.481,4.241,9.481,9.481v47.407C227.556,100.056,223.315,104.296,218.074,104.296z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M218.074,189.63c-5.241,0-9.481-4.241-9.481-9.481v-47.407c0-5.241,4.241-9.481,9.481-9.481
		c5.241,0,9.481,4.241,9.481,9.481v47.407C227.556,185.389,223.315,189.63,218.074,189.63z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M218.074,274.963c-5.241,0-9.481-4.241-9.481-9.481v-47.407c0-5.241,4.241-9.481,9.481-9.481
		c5.241,0,9.481,4.241,9.481,9.481v47.407C227.556,270.722,223.315,274.963,218.074,274.963z"
      />
    </g>
    <path
      style="fill:#FFC850;"
      d="M322.37,123.259h-37.926c-5.236,0-9.482-4.245-9.482-9.481V56.889c0-5.236,4.245-9.481,9.482-9.481
	h37.926c5.236,0,9.482,4.245,9.482,9.481v56.889C331.852,119.014,327.607,123.259,322.37,123.259z"
    />
    <path
      style="fill:#E4EAF8;"
      d="M218.074,293.926c-5.241,0-9.481,4.241-9.481,9.481h18.963
	C227.556,298.167,223.315,293.926,218.074,293.926z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M189.63,47.407c0-5.241-4.241-9.481-9.481-9.481s-9.481,4.241-9.481,9.481v256h18.963V47.407z"
    />
    <rect
      x="170.667"
      y="284.444"
      style="fill:#E4EAF8;"
      width="18.963"
      height="18.963"
    />
    <path
      style="fill:#E1A546;"
      d="M331.852,75.852h-56.889v18.963h18.963v9.481c0,5.236,4.245,9.481,9.481,9.481
	s9.481-4.245,9.481-9.481v-9.481h18.963V75.852z"
    />
    <circle style="fill:#FF507D;" cx="303.407" cy="227.556" r="28.444" />
    <path
      style="fill:#FFC850;"
      d="M331.852,284.444c0-15.71-12.735-28.444-28.444-28.444s-28.444,12.735-28.444,28.444
	c0,7.319,2.841,13.922,7.383,18.963h42.125C329.011,298.367,331.852,291.763,331.852,284.444z"
    />
    <path
      style="fill:#E1A546;"
      d="M274.963,284.444c0,7.319,2.841,13.922,7.383,18.963h42.125c4.54-5.041,7.383-11.644,7.383-18.963
	H274.963z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
